<template>
  <div>
    <!-- Filters -->
    <basic-filters
      @basic-filters-search="search"
      @basic-filters-changed="set_filters"
      :allowed_filters="allowed_filters"
      :suburb_set_to_use="suburb_set_to_use"
      page="qldlistings"
    />
    <listings-sidebar :sidebar="sidebar" :listing="sidebar_listing" @toggle_sidebar="(val) => (sidebar = val)" />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row class="d-flex align-items-baseline justify-content-center">
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-baseline justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select
              v-model="options.perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <!-- <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="options.filter" class="d-inline-block mr-1" placeholder="Search Current Page" />
            </div>
          </b-col> -->

          <b-col cols="12" md="6">
            <div class="d-flex align-items-baseline justify-content-end">
              <b-button variant="flat-primary" @click="initialize()">
                <feather-icon icon="RefreshCwIcon" class="mr-25" />
                <span class="align-middle">Refresh</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="listingtable"
        primary-key="listing_id"
        :items="listings"
        :fields="tableColumns"
        :sort-by.sync="options.sortBy"
        :sort-desc.sync="options.sortDesc"
        :current-page.sync="options.currentPage"
        per-page="0"
        :filter="null"
        :api-url="null"
        show-empty
        class="position-relative"
        responsive
        empty-text="No matching records found"
        sticky-header="70vh"
        hover
        :busy="busy"
        head-variant="light"
        no-sort-reset
        @row-clicked="show_listing_sidebar"
      >
        <!-- Column: address -->
        <template #cell(address)="data">
          <div class="d-flex align-items-baseline justify-content-between" style="width: 430px">
            <div class="pr-1">{{ data.item.address }}</div>
            <span class="small-text">({{ data.item.status_date == data.item.input_date ? "New" : "Updated" }})</span>
          </div>
        </template>
        <!-- Column: Status -->
        <template #cell(links)="data">
          <div class="d-flex align-items-baseline justify-content-center">
            <font-awesome-icon @click="open_link('https://www.google.com/search?q=' + data.item.address)" class="link-icons" icon="fa-brands fa-google" />
            <font-awesome-icon @click="open_link(data.item.listing_web_address)" class="link-icons" icon="fa-solid fa-arrow-up-right-from-square" />
            <font-awesome-icon
              @click="show_listing_sidebar(data.item)"
              class="link-icons"
              icon="fa-solid fa-circle-info"
              :color="data.item.property.length ? '#1F5A94' : 'grey'"
            />
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }" disabled>
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Save Listing</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <!-- pagination section -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">
              Showing {{ (options.currentPage - 1) * options.perPage }} to {{ (options.currentPage - 1) * options.perPage + options.perPage }} of
              {{ total_listings }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="options.currentPage"
              :per-page="options.perPage"
              :total-rows="total_listings"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink, BBadge, BDropdown, BDropdownItem, BPagination } from "bootstrap-vue";
import vSelect from "vue-select";
import ListingsSidebar from "./components/ListingsSidebar.vue";
import BasicFilters from "./components/BasicFilters.vue";

export default {
  name: "QLDListings",
  components: {
    BasicFilters,
    ListingsSidebar,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BMedia,
    vSelect,
  },
  data() {
    return {
      listings: [],
      loading: false,
      tableColumns: [
        {
          key: "address",
          label: "Address",
          sortable: true,
          variant: "warning",
          stickyColumn: true,
        },
        // {
        //   key: "status",
        //   label: "Status",
        //   sortable: true,
        // },

        // {
        //   key: "property_type",
        //   label: "Type",
        //   sortable: true,
        // },
        // {
        //   key: "property_type_other",
        //   label: "Category",
        //   sortable: true,
        // },
        {
          key: "city_plan_",
          label: "Zone",
          sortable: false,
          formatter: (v, k, item) => (item.property.length ? item.property[0].zone.replace("QPP-", "") : ""),
        },
        {
          key: "area",
          label: "Size",
          sortable: false,
          formatter: (v, k, item) => (item.property.length ? item.property[0].area.toFixed(1) : ""),
        },
        {
          key: "price",
          label: "Price",
          sortable: true,
        },
        {
          key: "status_date",
          label: "Updated",
          sortable: true,
        },

        "links",
        // "actions"
      ],

      // table options
      options: {
        currentPage: 1,
        perPage: 50,
        sortBy: "status_date",
        sortDesc: true,
        // filter: null,
        // apiUrl: null,
      },
      default_options: {
        currentPage: 1,
        perPage: 50,
        sortBy: "status_date",
        sortDesc: true,
        // filter: null,
        // apiUrl: null,
      },

      total_listings: 0,
      perPageOptions: [10, 50, 100, 150, 200, 250],
      // pagination: { from: 0, to: 0, of: 0 },
      busy: false,

      // sidebar fields
      sidebar: false,
      sidebar_listing: null,

      // filtrs options
      filters: [],
      allowed_filters: ["address", "suburb", "postcode", "council", "property_type", "property_type_other", "fall"],
      suburb_set_to_use: "qld_suburbs",
    };
  },

  watch: {
    options: {
      deep: true,
      handler() {
        this.load_listings();
      },
    },
  },
  async mounted() {
    try {
      this.initialize();
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    async initialize() {
      try {
        // changing options would trigger load_listings() by the watcher
        this.options = Object.assign({}, this.default_options);
      } catch (error) {
        console.log(error);
      }
    },
    async load_listings() {
      try {
        this.busy = true;
        let res = await this.$axios.post(`${this.$API_BASE_LISTINGS}/getqldlistings`, { filters: this.filters, options: this.options });
        this.busy = false;
        // console.log(res.data);
        if (!res.data.success) throw new Error("listings could not be fetched");

        this.listings = res.data.listings;
        this.total_listings = res.data.count;
      } catch (error) {
        this.busy = false;
        console.log(error);
      }
    },
    async search(filters) {
      this.filters = filters;
      await this.load_listings();
    },
    set_filters(filters) {
      this.filters = filters;
    },
    show_listing_sidebar(listing) {
      console.log(listing);
      this.sidebar_listing = listing;
      this.sidebar = true;
    },
    open_link(link) {
      window.open(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.link-icons {
  margin-right: 3px;
  cursor: pointer;
  font-size: 1em;
  font-weight: 800;
}
.small-text {
  font-size: 11px;
  font-weight: bold;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
